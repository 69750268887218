import React from 'react'
import logofooter from '../../../assets/images/logofooter.png'
import Facebook from '../../../assets/images/facebook.png'
import twitter from '../../../assets/images/twitter.png'
import instagram from '../../../assets/images/instagram.png'
import mail from '../../../assets/images/mail.png'
import location from '../../../assets/images/location-arrow.png'
import phone from '../../../assets/images/phone.png'
import linkedin from '../../../assets/images/linkedin.png'
import footerFacebook from '../../../assets/images/footerFacebook.png'
import footerInsta from '../../../assets/images/footerInsta.png'
import footerTwitter from '../../../assets/images/footerTwitter.png'
import footerLinkdin from '../../../assets/images/footerLinkdin.png'
import { Link, useNavigate } from 'react-router-dom';
import { paths } from '../../../config/constant'


function Footer() {
    const navigate = useNavigate();
    return (
        <div className="footerBg" style={{ borderTop: "1px solid #646161b3" }}>
            <div className='container mt-3'>
                <div className='row justify-content-between fs5'>
                    <div className='col-md-4 text-start'>
                        <p className=' my-2' role="button" onClick={()=>navigate(paths.home)}><img src={logofooter} alt="" /></p>
                        <p className='text-start text-muted w-75'><small></small></p>
                        <div className="fw-bold my-2">Follow Us on:</div>
                        <div className=''>
                            <img src={footerFacebook} alt="" className='footer-social-icon footerSocialIcon' />
                            <img src={footerTwitter} alt="" className='footer-social-icon footerSocialIcon ms-4' />
                            <img src={footerInsta} alt="" className='footer-social-icon footerSocialIcon ms-4' />
                            <img src={footerLinkdin} alt="" className='footer-social-icon footerSocialIcon ms-4' />
                        </div>
                    </div>
                    <div className='col-md-2 text-start'>
                        <ul className='list-unstyled bolder'>
                            <li className='mt-1 fw-bold fs5'>QUICK LINKS</li>
                            <li className='mt-1'><small><Link to={paths.home} className="text-black text-decoration-none">Home</Link></small></li>
                            <li className='mt-1'><small><Link to={paths.aboutus} className="text-black text-decoration-none">About us</Link></small></li>
                            <li className='mt-1'><small><Link to={paths.solutionServices} className="text-black text-decoration-none">Services</Link></small></li>
                            <li className='mt-1'><small><Link to={paths.insight} className="text-black text-decoration-none">Insight</Link></small></li>
                        </ul>
                    </div>
                    <div className='col-md-2 text-start'>
                        <ul className='list-unstyled bolder' style={{marginTop:'35px'}}>
                            <li className='mt-1'><small><Link to="/contact-us" className="text-black text-decoration-none">Contact Us</Link></small></li>
                            <li className='mt-1'><small>Privacy policy</small></li>
                            <li className='mt-1'><small>Terms & Conditions</small></li>
                            <li className='mt-1 dShow'><small><a href="https://portal.reins-global.com" target="_blank" className="text-black text-decoration-none">RI FAC</a></small></li>
                        </ul>
                    </div>
                    <div className='col-md-3 justify-content-center'>
                        <ul className='list-unstyled text-start bolder'>
                            <li className='my-1 fw-bold fs5'>CONTACT US</li>
                            <li className='mt-4'><img src={mail} alt="" className='me-2 footer-contact' /><small>support@bharatre.in</small></li>
                            <li className='mt-4'><img src={phone} alt="" className='me-2 footer-contact' /><small>+91 44 4341 8700</small></li>
                            <li className='mt-4'><img src={location} alt="" className='me-2 footer-contact' /><small>Chennai, Tamil Nadu, India</small></li>
                        </ul>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-4">
                        <div className="fw-bold text-center mb-2 fs5">CITIES</div>
                        <div className="d-flex flex-wrap justify-content-between">
                            <div className="" role="button" onClick={() => navigate(paths.mumbai)}><small>India</small></div>
                            <div className="" role="button" onClick={() => navigate(paths.labuan)}><small>Labuan</small></div>
                            {/* <div className="" role="button" onClick={() => navigate(paths.chennai)}><small>Chennai</small></div> */}
                            <div className="" role="button" onClick={() => navigate(paths.dubai)}><small>Dubai</small></div>
                            <div className="" role="button" onClick={() => navigate(paths.nairobi)}><small>Nairobi</small></div>
                        </div>
                        <p className='text-center mt-2 bolder'><small>Copyright &#169;2023 All rights reserved</small></p>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Footer;
