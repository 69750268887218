const base = '/';

let apiBase = "https://api.reins-global.com/api/";
if (process.env.NODE_ENV === "production") {
    apiBase = "https://api.reins-global.com/api/";
}
export const paths = {
    home: base,
    // auth:{
    //     login: base + 'login'
    // },
    // accounts: {
    //     home: base + 'accounts',
    //     homePageLogin: base + 'accounts/home-page-login',
    //     logout: base + 'accounts/logout',
    // },

    homepage: base + 'home-page',
    insight: base + 'insight-page',
    carrer: base + 'career-page',
    // assosiate: base + 'assosiate',
    aboutus: base + 'about-us',
    contactus: base + 'contact-us',
    solutionServices: base + 'services',
    clientele: base + 'clientele',
    
    mumbai: base + 'india',
    labuan: base + 'labuan',
    dubai: base + 'dubai',
    chennai: base + 'chennai',
    nairobi: base + 'nairobi',

    // media: base + 'media',
    // managementLeadership: base + 'management-leadership',

    // API url
    api: {
        joinNow: apiBase + 'web_admin/join-me/register',
        careerList: apiBase + 'web_admin/website/career',
        applyCareer: apiBase + 'web_admin/register/career-post',
        insightList: apiBase + 'web_admin/website/insights-document-on-website',
    },

}
